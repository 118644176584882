import React from "react"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import Link from "../components/link"

import ProfileImage from "../components/image.profile"

const StyledProfileImageContainer = styled.div`
  aspect-ratio: 16 / 9;
  overflow: hidden;
`

const Home = () => (
  <Layout>
    <StyledProfileImageContainer>
      <ProfileImage />
    </StyledProfileImageContainer>
    <p>
      I'm <strong>Patrick Carroll</strong>, a designer and software developer
      with experience building for web, mobile and cloud.
    </p>
    <p>
      You can find me on{" "}
      <Link
        external
        key="linked"
        to="https://www.linkedin.com/in/patrick-carroll-06806911"
      >
        LinkedIn
      </Link>
      ,{" "}
      <Link external key="polywork" to="https://polywork.com/patrickcarroll">
        Polywork
      </Link>{" "}
      and{" "}
      <Link external key="github" to="https://github.com/pdcarroll">
        GitHub
      </Link>
      .
    </p>
  </Layout>
)

export default Home
